/* eslint-disable max-len */
/* eslint-disable max-statements */
import {
  GET_HOME_FEED,
  GET_HOME_FEED_SUCCESS,
  GET_HOME_FEED_ERROR,
  GET_REFERRALS_DETAILS,
  GET_REFERRALS_DETAILS_SUCCESS,
  GET_REFERRALS_DETAILS_ERROR
} from "./actionTypes";

const initialState = {
  loader: false,

  fetchedHomeFeed: false,
  homeFeed: {},
  errorInFetchingHomeFeed: false,

  fetchedReferralsDetails: false,
  referralsDetails: [],
  errorInFetchingReferralsDetails: false
};

const ReferNEarnReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case GET_HOME_FEED:
      return {
        ...state,
        loader: true
      };

    case GET_HOME_FEED_SUCCESS:
      return {
        ...state,
        loader: false,
        errorInFetchingHomeFeed: false,
        fetchedHomeFeed: true,
        homeFeed: payload
      };

    case GET_HOME_FEED_ERROR:
      return {
        ...state,
        loader: false,
        errorInFetchingHomeFeed: true
      };

    case GET_REFERRALS_DETAILS:
      return {
        ...state,
        loader: true
      };

    case GET_REFERRALS_DETAILS_SUCCESS:
      return {
        ...state,
        loader: false,
        errorInFetchingReferralsDetails: false,
        fetchedReferralsDetails: true,
        referralsDetails: payload?.concatData ? state.referralsDetails.concat(payload?.data) : payload?.data,
        latestReferralsDetails: payload?.data?.length || 0
      };

    case GET_REFERRALS_DETAILS_ERROR:
      return {
        ...state,
        loader: false,
        errorInFetchingReferralsDetails: true
      };

    default:
      return state;
  }
};

export default ReferNEarnReducer;
