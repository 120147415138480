import { all, spawn } from "redux-saga/effects";

import listSagas from "./list.sagas";
import signUpSagas from "../pages/signUp/saga/signupSaga";
import onbasicDetailsagas from "../pages/basicDetails/saga/basicDetailsSaga";
import onCommonDetailsagas from "../pages/commonDetails/saga";
import onLoginSagas from "../pages/login/saga/loginSaga";
import onDealsAndOfferPostssagas from "../pages/DealsAndOffers/saga/dealsAndOfferSaga";
import feedbackSagas from "../pages/feedback/saga/feedbackSaga";
import customerServiceSagas from "../pages/CustomerServices/saga";
import onBankingSagas from "../pages/banking/saga/bankingSaga";
import TicketSummarySagas from "../pages/TicketSummary/saga";
import insuranceSagas from "../pages/InsuranceJourney/Redux/saga/insuranceSaga";
import CLISagas from "../pages/CLIJourney/Redux/saga";
import LoanRestructureSagas from "../pages/LoanRestructure/Redux/saga";
import KYCSagas from "../pages/KYC/Redux/saga";
import GoldWaitlistSagas from "../pages/GoldWaitlist/Redux/saga";
import UpiCollectSagas from "../pages/UpiCollect/Redux/saga";
import DealsAndOffersSaga from "../pages/DealsAndOffersNew/Redux/saga";
import ClevertapSaga from "./clevertapSaga";
import referAndEarnSaga from "../pages/ReferAndEarn/Redux/saga";
import GameEngineSaga from "../pages/GameEngine/Redux/saga";
import WealthSaga from "../pages/Wealth/Redux/saga";
import IvrSaga from "../pages/Ivr/Redux/saga";
import ReferNEarnSaga from "../pages/ReferNEarn/Redux/saga";

export default function* rootSaga() {
  yield all([
    spawn(listSagas),
    spawn(signUpSagas),
    spawn(onbasicDetailsagas),
    spawn(onCommonDetailsagas),
    spawn(onLoginSagas),
    spawn(onDealsAndOfferPostssagas),
    spawn(feedbackSagas),
    spawn(customerServiceSagas),
    spawn(onBankingSagas),
    spawn(TicketSummarySagas),
    spawn(insuranceSagas),
    spawn(CLISagas),
    spawn(LoanRestructureSagas),
    spawn(KYCSagas),
    spawn(GoldWaitlistSagas),
    spawn(UpiCollectSagas),
    spawn(DealsAndOffersSaga),
    spawn(ClevertapSaga),
    spawn(referAndEarnSaga),
    spawn(GameEngineSaga),
    spawn(WealthSaga),
    spawn(IvrSaga),
    spawn(ReferNEarnSaga)
  ]);
}
