import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_API_URL;

export const getHomeFeedApiCall = async () => {
  try {
    const res = await axios.get(`${BASE_URL}rewards/v1/referAndEarn/getHomeFeed`, {
      headers: {
        auth_token: localStorage.getItem("auth_token"),
        device_id: localStorage.getItem("device_id")
      }
    });
    return res;
  } catch (err) {
    return err.response;
  }
};

export const getTotalEarningsApiCall = async () => {
  try {
    const res = await axios.get(`${BASE_URL}rewards/v1/referAndEarn/getTotalEarnings`, {
      headers: {
        auth_token: localStorage.getItem("auth_token"),
        device_id: localStorage.getItem("device_id")
      }
    });
    return res;
  } catch (err) {
    return err.response;
  }
};

export const getReferralsDetailsApiCall = async ({ payload }) => {
  try {
    const res = await axios.get(
      `${BASE_URL}rewards/v1/referAndEarn/getReferralsDetails?page=${payload?.page}&limit=${payload?.limit}`,
      {
        headers: {
          auth_token: localStorage.getItem("auth_token"),
          device_id: localStorage.getItem("device_id")
        }
      }
    );
    return res;
  } catch (err) {
    return err.response;
  }
};
