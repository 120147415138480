/* eslint-disable max-len */
import { call, put, takeLatest, all } from "redux-saga/effects";

import {
  GET_HOME_FEED,
  GET_HOME_FEED_SUCCESS,
  GET_HOME_FEED_ERROR,
  GET_REFERRALS_DETAILS,
  GET_REFERRALS_DETAILS_SUCCESS,
  GET_REFERRALS_DETAILS_ERROR
} from "./actionTypes";

import { getHomeFeedApiCall, getTotalEarningsApiCall, getReferralsDetailsApiCall } from "./services";

const commonError = "Something went wrong, please try again later";

function* getHomeFeed() {
  try {
    const [response1, response2] = yield all([call(getHomeFeedApiCall), call(getTotalEarningsApiCall)]);
    if (response1.status === 200 && response2.status === 200) {
      yield put({
        type: GET_HOME_FEED_SUCCESS,
        payload: {
          getHomeFeedApiResponse: response1.data,
          getTotalEarningsApiResponse: response2.data
        }
      });
    } else {
      yield put({
        type: GET_HOME_FEED_ERROR,
        payload: { error: response1.data?.error || response2.data?.error || commonError }
      });
    }
  } catch (error) {
    yield put({ type: GET_HOME_FEED_ERROR, payload: { error: commonError } });
  }
}

function* getReferralsDetails({ payload }) {
  try {
    const response = yield call(getReferralsDetailsApiCall, { payload });
    if (response.status === 200 && response?.data?.data?.length > 0) {
      yield put({
        type: GET_REFERRALS_DETAILS_SUCCESS,
        payload: { data: response?.data?.data, concatData: payload?.concatData }
      });
    } else {
      yield put({ type: GET_REFERRALS_DETAILS_ERROR, payload: { error: response?.data?.error || commonError } });
    }
  } catch (error) {
    yield put({ type: GET_REFERRALS_DETAILS_ERROR, payload: { error: commonError } });
  }
}

function* ReferNEarnSaga() {
  yield takeLatest(GET_HOME_FEED, getHomeFeed);
  yield takeLatest(GET_REFERRALS_DETAILS, getReferralsDetails);
}

export default ReferNEarnSaga;
